import React, { useState }  from 'react';
import { iframeResize }     from 'iframe-resizer';
import { SpinnerContainer } from '../../modules/Spinner/Spinner';
import { Spinner }          from '@chakra-ui/react';

const ResizingIFrame = ({ src, id }) => {
  const [loading, setLoading] = useState(true)
  const onLoad = () => {
    iframeResize({ log: true }, `#${id ? id : src}`);
    setLoading(false);
  }
  return (
    <>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      <iframe width="100%" id={id ? id : src} src={src} onLoad={onLoad}/>
    </>
  )
}

export default ResizingIFrame;
