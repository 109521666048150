import React       from 'react';
import { GiWatch } from 'react-icons/gi';


import Spacer         from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading        from '@interness/web-core/src/components/text/Heading/Heading';
import ResizingIFrame from '@interness/web-core/src/components/structure/iFrame/ResizingIFrame';

const ProduktePage = () => {
  return (
    <div>
      <Spacer/>
      <Heading icon={<GiWatch/>} subtitle={'Einfach lokal erwerben'}>Aktuelle Highlights</Heading>
      <ResizingIFrame id="iFrameMapAds"
                      src={'https://nearbuyer.de/iframe/store/juwelier-grimm-11980?iframeStoreDetails=false'}
                      title={'NearBuyer'}/>
    </div>
  )
}

export default ProduktePage;
